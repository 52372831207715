import React, {useEffect, useState} from 'react';
import './Rules.css';
import AOS from "aos";

const Rules = () => {
  const [currentRule, setCurrentRule] = useState('sklep');

  const showRule = (rule) => {
    setCurrentRule(rule);
  };


  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease',
      once: false,
    });
  }, []);

  return (
      <div className="rules-wrapper">
        <div className="rules" data-aos="zoom-in-up">
          <div className="title4" data-aos="zoom-in-up">
            <h2>REGULAMIN</h2>
          </div>
          <hr className="rules-line" data-aos="zoom-in-up"/>
          <div className="container-rules-out">
            <div className="container-rules" data-aos="zoom-in-up">
              <div className="button-group">
                <button
                    className={currentRule === 'sklep' ? 'rule-button active' : 'rule-button'}
                    onClick={() => showRule('sklep')}
                >
                  REGULAMIN SKLEPU
                </button>
                <button
                    className={currentRule === 'serwer' ? 'rule-button active' : 'rule-button'}
                    onClick={() => showRule('serwer')}
                >
                  REGULAMIN SKLEPU
                </button>
              </div>
              <div className="rule-content">
                <div className="rule-description">
                  {currentRule === 'sklep' && (
                      <>
                        <h2>1. Podstawowe definicje</h2>
                        <p className="indent">1.1 Serwis internetowy/Usługodawca – serwis internetowy dostępny pod adresem https://www.infinityrpg.pl/, będący jednocześnie Usługodawcą.</p>
                        <p className="indent">1.2 Właściciel – DAMIAN KRAUZE LUMINO STUDIO, NIP: 7722437856, REGON: 529474889.</p>
                        <p className="indent">1.3 Adres Właściciela – ilekroć w Regulaminie jest mowa o adresie Właściciela rozumie się przez to następujące dane: a) siedzibę: ul. Władysława Pytlasińskiego, nr 16, lok. 13, 00-777, Warszawa b) adres mailowy: kontakt@infinityrpg.pl.</p>
                        <p className="indent">1.4 Użytkownik/Usługobiorca – osoba fizyczna, która ukończyła 18 lat i posiada pełną zdolność do czynności prawnych, a w wypadkach przewidzianych przez przepisy powszechnie obowiązujące także osoba fizyczna posiadająca ograniczoną zdolność do czynności prawnych, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, która zawarła lub zamierza zawrzeć umowę z Serwisem internetowym.</p>
                        <p className="indent">1.5 Konsument – art. 221 KC: osoba fizyczna dokonująca z Serwisem internetowym czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową.</p>
                        <p className="indent">1.6 Umowa zawarta na odległość – oznacza umowę zawartą pomiędzy Serwisem internetowym a Użytkownikiem w ramach zorganizowanej procedury systemu zawierania umów na odległość, bez konieczności uczestnictwa obu stron umowy w jednym miejscu i czasie, zawartej za pomocą jednego lub większej liczby środków niezbędnych do porozumiewania się na odległość do chwili zawarcia umowy włącznie.</p>
                        <p className="indent">1.7 Usługa – usługa świadczona drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy (Klienta) za pośrednictwem niniejszego Serwisu internetowego tj: Serwis umożliwia graczom zakupienie specjalnych czasowych przywilejów, rang, voucherów i innych tego typu produktów; Produkty są możliwe do odebrania w grze pod komendą /itemshop oraz /is.</p>
                        <p className="indent">1.8 Ustawa o prawach Konsumenta, ustawa – ustawa z dnia 30 maja 2014 r. o prawach konsumenta.</p>

                          <h2>2. Postanowienia ogólne</h2>
                        <p className="indent">2.1 Serwis internetowy niniejszym oświadcza, że zobowiązuje się do świadczenia usług na rzecz Usługobiorcy w sposób rzetelny i odpowiadający obowiązującym przepisom prawa, zasadom współżycia społecznego oraz w sposób uregulowany w tym Regulaminie.</p>
                        <p className="indent">2.2 Serwis internetowy oświadcza, że przestrzega wszelkich wymaganych zasad ochrony danych osobowych Usługobiorców jakie są przewidziane między innymi Ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych oraz zgodnie z przepisami Rozporządzenia Rady UE 2016/679 (z dnia 27 kwietnia 2016 r. Dz. Urz. UE.L. Nr 119). Usługobiorca wyraża zgodę na gromadzenie, przechowywanie i przetwarzanie przez Serwis internetowy danych osobowych wyłącznie w celu bezpośrednio związanym z realizacją zadań serwisu. Szczegółowe warunki gromadzenia, przetwarzania oraz ochrony danych osobowych przez Serwis internetowy określone zostały w „Polityce prywatności” Serwisu internetowego.</p>
                        <p className="indent">2.3 Użytkownik ma możliwość zapoznania się z Regulaminem, akceptując jego treść poprzez oznaczenie odpowiedniego pola w formularzu. Do korzystania z Serwisu internetowego konieczna jest akceptacja postanowień regulaminu.</p>
                        <p className="indent">2.4 Administratorem danych osobowych jest Serwis internetowy, a dane te podlegają ochronie zgodnie z Ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych oraz zgodnie z przepisami Rozporządzenia Rady UE 2016/679 (z dnia 27 kwietnia 2016 r. Dz. Urz. UE.L. Nr 119). Zbiór danych osobowych zarejestrowany jest w GIODO – Generalny Inspektor Ochrony Danych Osobowych.</p>
                        <p className="indent">2.5 Administrator danych stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę danych osobowych miarodajnie do zagrożeń oraz kategorii danych objętych ochroną. Przede wszystkim chroni dane przed ich udostępnieniem, zabraniem, przetwarzaniem, utratą, zmianą uszkodzeniem lub zniszczeniem przez osoby do tego nieuprawnione.</p>
                        <p className="indent">2.6 Administratorem Państwa danych osobowych jest DAMIAN KRAUZE LUMINO STUDIO, NIP: 7722437856, REGON: 529474889, Adres: ul. Władysława Pytlasińskiego, nr 16, lok. 13, 00-777, Warszawa, adres mailowy: kontakt@infinityrpg.pl.</p>
                        <p className="indent">2.7 Każdej osobie, której dane są przetwarzane, przysługuje prawo do: a) nadzorowania i kontroli przetwarzania danych osobowych, dla których Serwis internetowy prowadzi zbiór danych Użytkowników w/w Serwisu internetowego; b) uzyskania wyczerpującej informacji, czy taki zbiór istnieje i jest prowadzony przez Serwis internetowy; c) ustalenia, kto jest administratorem danych, ustalenia jego adresu, siedziby, nazwy, w sytuacji gdy administratorem jest osoba fizyczna do ustalenia jej imienia i nazwiska oraz miejsca zamieszkania; d) uzyskania informacji o celu, zakresie, sposobie, czasie przetwarzania danych zawartych w takim zbiorze; e) uzyskania informacji w powszechnie zrozumiałej formie treści tych danych; f) poznania źródła, z którego pochodzą dane, które jej dotyczą, chyba że administrator danych jest zobowiązany do zachowania w tym zakresie w tajemnicy informacji niejawnych lub zachowania tajemnicy zawodowej; g) żądania uzupełnienia, uaktualnienia, sprostowania danych osobowych, czasowego zawieszenia lub ich usunięcia, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z naruszeniem ustawy albo są już zbędne do realizacji celu, dla którego zostały zebrane.</p>
                        <p className="indent">2.8 Użytkownikowi zgodnie z punktem 7 przysługuje prawo wglądu w treść przetwarzanych danych osobowych, ich poprawiania, a także żądania usunięcia tych danych. Administrator danych osobowych jest obowiązany, do uzupełnienia, uaktualnienia, sprostowania danych, czasowego lub stałego wstrzymania przetwarzania lub ich usunięcia ze zbioru na bieżąco i od razu po zgłoszeniu, chyba że żądanie dotyczy danych osobowych, co do których tryb ich uzupełnienia, uaktualnienia lub sprostowania określają odrębne przepisy prawa w tym ustawy.</p>
                        <p className="indent">2.9 Usługobiorca wyraża zgodę na gromadzenie i przetwarzanie przez Serwis danych osobowych w rozumieniu Ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych oraz zgodnie z przepisami Rozporządzenia Rady UE 2016/679 (z dnia 27 kwietnia 2016 r. Dz. Urz. UE.L. Nr 119). Dane mogą być przekazywane innemu podmiotowi tylko w sytuacji prawem wymaganych bądź niezbędnych dochodzenia roszczeń.</p>
                        <p className="indent">2.10 Usługobiorca zobowiązuje się do korzystania z Serwisu internetowego zgodnie z obowiązującymi przepisami prawa i zasadami współżycia społecznego.</p>
                        <p className="indent">2.11 Usługobiorca korzystający z Usług Serwisu internetowego, zobowiązany jest do przestrzegania niniejszego Regulaminu w zakresie jaki jest niezbędny do realizacji zadań Serwisu i w zakresie który nie jest sprzeczny z obowiązującym prawem oraz z zasadami współżycia społecznego.</p>
                        <p className="indent">2.12 Usługodawca jest uprawniony do udostępnienia uprawnionym organom państwowym treści, materiałów oraz danych, w tym adresów IP Usługobiorców, którzy korzystali z Serwisu w określony sposób, w szczególności, gdy jest to niezbędne w celu zapobiegania lub ścigania przestępstw. W takiej sytuacji Usługodawca nie opowiada także za ewentualne zablokowanie dostępu do określonych danych i informacji.</p>
                        <p className="indent">2.13 Przedsiębiorca prowadzący jednoosobowo działalność gospodarczą, jeśli dokona zakupu, który nie ma związku z prowadzoną przez niego działalnością, posiada prawo do odstąpienia od umowy w terminie 14 dni kalendarzowych od momentu objęcia Towaru w posiadanie przez Klienta lub osobę trzecią przez niego wskazaną inną niż przewoźnik.</p>
                        <p className="indent">2.14 Dla przypomnienia. W związku z nowymi przepisami Kodeksu cywilnego ustawodawca zaplanował także dodanie art. 38a do Ustawy o prawach konsumenta, który umożliwi jednoosobowym firmom korzystanie z 14-dniowego prawa zwrotu – w poniższym brzmieniu: ,,Art. 38a Przepisy dotyczące konsumenta zawarte w niniejszym rozdziale stosuje się do osoby fizycznej zawierającej umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.”</p>
                        <p className="indent">2.15 Art. 556(4) Zawarte w niniejszym dziale przepisy dotyczące konsumenta, z wyjątkiem art. 558 § 1 zdanie drugie, stosuje się do osoby fizycznej zawierającej umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.</p>
                        <p className="indent">2.16 Weryfikacja tego, czy dana czynność ma zawodowy charakter, będzie odbywać się w oparciu o CEiDG — Centralną Ewidencję i Informację o Działalności Gospodarczej — a konkretnie o wpisane tam kody PKD określające rodzaje działalności gospodarczej.</p>
                        <p className="indent">2.17 Przedsiębiorcy prowadzący jednoosobowo działalność uzyskają uprawnienia w zakresie: niedozwolonych klauzul stosowanych we wzorcach umownych; rękojmi za wady rzeczy sprzedanej; roszczenia regresowego do poprzedniego sprzedawcy w związku z wykonaniem reklamacji konsumenta; prawa odstąpienia od umowy zawartej na odległość lub poza lokalem przedsiębiorstwa w terminie 14 dni.</p>
                        <p className="indent">2.18 Przepisy dotyczące konsumenta, zawarte w art. 385(1)-385(3) k.c. [dotyczących niedozwolonych postanowień umownych] stosuje się do osoby fizycznej zawierającej umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla niej charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.</p>
                        <p className="indent">2.19 Nowy art. 385(5) k.c. dotyczy tylko niedozwolonych postanowień umownych (klauzul abuzywnych). Przepisy o klauzulach abuzywnych będą po 1 stycznia 2021 r. stosowane do jednoosobowych przedsiębiorców. Katalog przykładowych dwudziestu trzech klauzul abuzywnych zawarty jest w art. 385(3) k.c. Z kolei w aktualnej wersji rejestru klauzul abuzywnych prowadzonego przez Prezesa UOKiK.</p>
                        <p className="indent">2.20 Nowe regulacje będą miały zastosowanie do umów zawartych po dniu 21 stycznia 2021 r. Przepisów art. 385[5], art. 556[4], art. 556[5] i art. 576[5] ustawy zmienianej w art. 1 nie stosuje się do umów zawartych przed dniem 1 stycznia 2021 r. Przepisu art. 38a ustawy zmienianej w art. 55 nie stosuje się do umów zawartych przed dniem 1 stycznia 2021 r.</p>
                        <p className="indent">2.21 Przedsiębiorcy prowadzący jednoosobowo działalność gospodarczą nadal nie będą mogli korzystać z pomocy instytucji wspierających konsumentów w ochronie ich praw, w tym z pomocy Powiatowych/Miejskich Rzeczników Konsumenta czy UOKIK.</p>

                            <h2>3.Warunki świadczenia usług</h2>
                        <p className="indent">3.1 Serwis internetowy świadczy usługi za pomocą drogi elektronicznej.</p>
                        <p className="indent">3.2 Usługa wymaga dostępu do sieci internetowej.</p>
                        <p className="indent">3.3 Korzystać z Serwisu internetowego można 24 godziny na dobę, 7 dni w tygodniu. Serwis zastrzega sobie prawo do przeprowadzania przerw technicznych celem poprawy funkcjonalności Serwisu oraz naprawy błędów.</p>
                        <p className="indent">3.4 Klient finalizując transakcję w odpowiednim okienku zamówienia zaznacza opcję „Wyrażam zgodę na przetwarzanie przez serwis moich danych osobowych zawartych w formularzu zamówienia w celu i zakresie niezbędnym do realizacji zamówienia.” – jest ona niezbędna do zawarcia umowy. Podanie danych osobowych jest niezbędne do złożenia zamówienia, a ich niepodanie będzie równoznaczne z odstąpieniem od zawarcia umowy.</p>
                        <p className="indent">3.5 Zgodnie z art. 8 ust. 2 RODO, administrator podejmuje rozsądne starania, by zweryfikować, czy osoba sprawująca władzę rodzicielską lub opiekę nad dzieckiem (poniżej 16 lat) wyraziła zgodę lub ją zaaprobowała.</p>
                        <p className="indent">3.6 Osoba akceptująca regulamin potwierdza, że ukończyła 18 lat lub posiada zgodę opiekuna prawnego.</p>
                        <p className="indent">3.7 Wymagania niezbędne do korzystania z usług Serwisu internetowego to urządzenie z dostępem do sieci Internet, przeglądarka internetowa obsługująca pliki Cookies, na przykład: Internet Explorer w wersji 8.0 lub nowszej z włączoną obsługą ActiveX, JavaScript i Cookies, Mozilla Firefox w wersji 22.0 lub nowszej z włączoną obsługą apletów Javy, JavaScript i cookies, Google Chrome w wersji 28.0 lub nowszej z włączoną obsługą apletów Javy, JavaScript i cookies, Opera w wersji 12.0 lub nowszej z włączoną obsługą apletów Javy, JavaScript i cookies, Apple Safari 5.0 lub nowszej z włączoną obsługą apletów Javy, JavaScript i cookies oraz dostęp do poczty elektronicznej typu e-mail.</p>
                        <p className="indent">3.8 Koszty związane z dostępem do sieci Internet i transmisją danych ponoszone są wyłącznie przez Użytkownika zgodnie z taryfą swojego dostawcy, z którym Użytkownik podpisał umowę o świadczenie usług internetowych.</p>
                        <p className="indent">3.9 W Serwisie internetowym wysyłamy maile służące do aktywacji konta, zmiany hasła oraz przypomnienia hasła. Przewidujemy również dodanie Newslettera.</p>

                              <h2> 4.Umowa świadczenia usług</h2>
                        <p className="indent">4.1 Dla dokonania zawarcia ważnej i wiążącej strony Umowy Klient dokonuje wyboru zgodnie z wyświetloną ofertą Serwisu internetowego określając ilość Towaru/Usługi jaką zamierza nabyć oraz w razie takiej możliwości wskazując cechy zamawianego Produktu oraz odpowiednio jego specyfikację. Łącznie z wyborem Towaru/Usługi, Klient wypełnia internetowy formularz zamówienia, wskazując w nim dane niezbędne do realizacji zamówienia przez Serwis takie jak na przykład ilości, miejsce oraz formy płatności, w oparciu o wyświetlane Klientowi komunikaty i informacje dostępne na stronie oraz zawarte w niniejszym Regulaminie.</p>
                        <p className="indent">4.2 Rejestracja Konta Klienta w Serwisie internetowym jest dobrowolna i nieodpłatna.</p>
                        <p className="indent">4.3 Niezwłocznie po otrzymaniu zamówienia Serwis przesyła Klientowi drogą elektroniczną na podany w trakcie składania zamówienia adres poczty elektronicznej oświadczenie o przyjęciu zamówienia stanowiące równocześnie jego potwierdzeniem. Z chwilą otrzymania wiadomości przez Klienta dochodzi do zawarcia umowy.</p>
                        <p className="indent">4.4 Aby móc w pełni korzystać z Serwisu Klient musi korzystać z oprogramowania BlazingPack dostępnego do pobrania na stronie https://BlazingPack.pl/.</p>
                        <p className="indent">4.5 Klient ma prawo do akceptacji lub odrzucenia dobranego mu przeciwnika, lecz nie ma bezpośredniego wglądu do statystyk ani jego pseudonimu. Dostaje on jedynie możliwość akceptacji lub odrzucenia znalezionej rozgrywki oraz informację o stawce o którą będzie rozgrywana dana rozgrywka.</p>
                        <p className="indent">4.6 Wiadomość podsumowująca i potwierdzająca zamówienie zawiera wszystkie wcześniej ustalone warunki umowy, a w szczególności ilość oraz rodzaj zamówionego Towaru/Usługi, jego specyfikację w przypadku zamówienia Towarów/Usług o indywidualnych właściwościach określonych przez Klienta Serwisu internetowego, całkowitą cenę do zapłaty (określoną w polskich złotych) wraz wysokością przyznanych rabatów (o ile dotyczy).</p>
                        <p className="indent">4.7 W przypadku posiadania przez Klienta większej ilości rabatów pochodzących z kilku źródeł/promocji podlegają one łączeniu/sumowaniu tylko w sytuacji gdy jest to wyraźnie określone w Regulaminie promocji. W przypadku braku zapisu co do sposobu łączenia różnych promocji/rabatów można wybrać tylko jeden rabat (jedną promocję) przy danym zakupie.</p>

                                <h2>5. Postępowanie reklamacyjne</h2>
                        <p className="indent">5.1 Usługobiorca ma prawo do składania reklamacji w zakresie Usług świadczonych przez Usługodawcę w ramach Serwisu internetowego.</p>
                        <p className="indent">5.2 Podmiotem uprawnionym do rozpatrywania reklamacji jest Usługodawca.</p>
                        <p className="indent">5.3 Reklamacje powinny być przesyłane na adres znajdujący się paragrafie 1 pkt 3 w formie pisemnej lub elektronicznej (wiadomość mailowa w polu temat wiadomości powinna zawierać słowo: "reklamacja") i powinny zawierać: przedmiot reklamacji oraz uzasadnienie reklamacji, wskazanie i opisanie niezbędnych okoliczności,</p>
                        <p className="indent">5.4 oznaczenie Usługobiorcy (imię, nazwisko, adres, adres e-mail).</p>
                        <p className="indent">5.5 Powyższe przesłanki stanowią warunek obligatoryjny rozpatrzenia przez Usługodawcę reklamacji.</p>
                        <p className="indent">5.6 Reklamacje będą rozpatrywane przez Usługodawcę w ciągu 14 dni od ich otrzymania. Decyzja Usługodawcy dotycząca reklamacji będzie przekazywana Usługobiorcy na adres email wskazany w reklamacji lub adres wskazany w korespondencji listownej.</p>

                                  <h2>6. Odstąpienie od umowy</h2>
                        <p className="indent">6.1 Zgodnie z przepisami prawa Klientowi będącemu Konsumentem w myśl art. 27 ustawy z dnia 30 maja 2014 r. (Dz.U. z 2014 r. poz. 827 z późn. zm.) o prawach Konsumenta, przysługuje prawo do odstąpienia od umowy zawartej na odległość bez podawania przyczyny.</p>
                        <p className="indent">6.2 Zgodnie z art. 38 pkt. 13 ustawy o prawach konsumenta – „ o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy.” – w takiej sytuacji prawo odstąpienia nie przysługuje.</p>
                        <p className="indent">6.3 Prawo do odstąpienia od umowy przysługuje w terminie 14 dni kalendarzowych od momentu objęcia Towaru/Usługi w posiadanie przez Klienta będącego jednocześnie Konsumentem lub osobę trzecią przez niego wskazaną inną niż przewoźnik.</p>
                        <p className="indent">6.4 Gdy Klient będący Konsumentem odstąpi od umowy, umowa jest uważana za niezawartą, a Konsument jest zwolniony wówczas z wszelkich zobowiązań. To co strony świadczyły sobie nawzajem podlega zwrotowi w stanie niezmienionym, chyba że zmiana była konieczna w granicach zwykłego zarządu.</p>
                        <p className="indent">6.5 Klient będący Konsumentem może odstąpić od umowy, składając oświadczenie na internetowym formularzu stanowiącym załącznik nr 1 do niniejszego Regulaminu, przesyłając je drogą elektroniczną lub na adres pocztowy Serwisu według wyboru Klienta. Załącznik nr 1 stanowi tylko pomoc w odstąpieniu od umowy, nie jest wzorem koniecznym do skorzystania z prawa do odstąpienia od umowy. Klient może ale nie musi z niego korzystać. Dla skutecznego odstąpienia wystarczy przesłanie oświadczenia na piśmie na adres Serwisu.</p>
                        <p className="indent">6.6 Do zachowania terminu określonego w pkt. 2 wystarczy wysłanie oświadczenia Klienta o odstąpieniu od umowy przed jego upływem.</p>
                        <p className="indent">6.7 Serwis niezwłocznie potwierdzi Klientowi otrzymanie oświadczenia o odstąpieniu od umowy i stosownie poinformuje Klienta o dalszym postępowaniu, w tym o sposobie zwrotu Towaru/Usługi oraz w razie pytań udzieli na nie odpowiedzi.</p>
                        <p className="indent">6.8 Serwis niezwłocznie, w terminie nie dłuższym niż 14 dni kalendarzowych od dnia otrzymania oświadczenia Klienta o odstąpieniu od umowy, zwróci Klientowi wszelkie otrzymane od niego płatności, w tym koszty dostarczenia rzeczy. Serwis dokonuje zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użył Klient, chyba że Klient wyraźnie zgodził się na inny sposób zwrotu płatności, który nie wiąże się dla niego z żadnymi kosztami.</p>
                        <p className="indent">6.9 Klient będący Konsumentem ponosi wyłącznie bezpośrednie koszty zwrotu Towaru.</p>
                        <p className="indent">6.10 Konsumentowi, przysługuje prawo do odstąpienia od umowy zawartej na odległość, bez podania przyczyny i bez ponoszenia kosztów, z wyjątkiem kosztów określonych w art. 33, art. 34 Prawa konsumenckiego.</p>
                        <p className="indent">6.11 Prawo do odstąpienia od umowy nie przysługuje Klientowi w odniesieniu do umów określonych w art. 38 ustawy z dnia 30 maja 2014 r. o prawach Konsumenta m. in. w sytuacji: o świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą Konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy; w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którymi przedsiębiorca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od umowy; w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb; w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia; w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu; w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter zostają nierozłącznie połączone z innymi rzeczami.</p>

                                    <h2> 7. Rękojmia</h2>
                        <p className="indent">7.1 Serwis odpowiada z tytułu rękojmi, jeżeli wada zostanie stwierdzona przed upływem dwóch lat od dnia wydania Towaru/Usługi Konsumentowi. Serwis odpowiada wobec Konsumenta jeżeli Towar/Usługa konsumpcyjny w chwili jego wydania był niezgodny z umową, posiada wady fizyczne, prawne. Serwis odpowiada za niezgodność Towaru/Usługi konsumpcyjnego z umową w przypadku jej stwierdzenia przed upływem dwóch lat od wydania tego Towaru/Usługi Kupującemu, przy czym w razie wymiany Towaru/Usługi termin ten biegnie na nowo. Wada fizyczna polega na niezgodności rzeczy sprzedanej z umową. W szczególności rzecz sprzedana jest niezgodna z umową, jeżeli: nie ma właściwości, które rzecz tego rodzaju powinna mieć ze względu na cel w umowie oznaczony albo wynikający z okoliczności lub przeznaczenia; nie ma właściwości, o których istnieniu Sprzedawca zapewnił Kupującego, w tym przedstawiając próbkę lub wzór; nie nadaje się do celu, o którym Kupujący poinformował Sprzedawcę przy zawarciu umowy, a Sprzedawca nie zgłosił zastrzeżenia co do takiego jej przeznaczenia; została Kupującemu wydana w stanie niezupełnym.</p>
                        <p className="indent">7.2 Zgłoszenie o wadach Towaru/Usługi należy przesłać drogą elektroniczną na adres mailowy Serwisu lub w formie pisemnej na adres pocztowy Serwisu (patrz §1 pkt 3.: „Adres Serwisu”). Jeśli konsument ma trudności i nie wie jak skonstruować zgłoszenie o wadach Towaru/Usługi, zgłoszenie może przesłać dla przykładu na formularzu stanowiącym załącznik nr 2 do niniejszego Regulaminu, co stanowi tylko i wyłącznie ułatwienie dla procesu reklamacji, nie stanowi żadnego wymogu korzystania z w/w wzorca dla skuteczności reklamacji.</p>
                        <p className="indent">7.3 Serwis odpowiada niezwłocznie na zgłoszenie Konsumenta jednak nie później niż w terminie do 14 dni kalendarzowych od dnia jego otrzymania. Nierozpatrzenie zgłoszenia w zakreślonym terminie jest równoznaczne z jego uwzględnieniem przez Serwis i uznaniem go za uzasadnione.</p>
                        <p className="indent">7.4 Serwis pokrywa koszty usunięcia wad lub wady i wymiany Towaru/Usługi na nowy.</p>

                                      <h2> 8. Odpowiedzialność</h2>
                        <p className="indent">8.1 Serwis internetowy nie odpowiada za treści (zarówno słowne jak i graficzne) udostępniane przez Użytkowników. W przypadku roszczeń osób trzecich związanych z naruszeniem praw autorskich, praw pokrewnych lub innych przysługujących im praw, Serwis będzie kierował je niezwłocznie do Usługobiorcy jako podmiotu odpowiedzialnego za treści, a Usługobiorca przyjmie te roszczenia i w tym zakresie zwolni od odpowiedzialności Serwis internetowy.</p>
                        <p className="indent">8.2 Serwis nie odpowiada za szkody wyrządzone przestępnym działaniem osób trzecich w tym za ataki hackerskie i próby wykorzystania błędów strony przez osoby do tego nieuprawnione.</p>
                        <p className="indent">8.3 Zgodnie z przepisami prawa Twórcy, którego autorskie prawa osobiste zostały naruszone bądź zagrożone, przysługuje Mu przede wszystkim roszczenie o zaniechanie tego działania, o przywrócenie stanu poprzedniego. Jeśli działanie, które wywołało naruszenie, było zawinione Twórca może domagać się zasądzenia zadośćuczynienia za doznaną krzywdę lub zasądzenia odpowiedniej sumy pieniężnej na wskazany cel społeczny.</p>
                        <p className="indent">8.4 Zamieszczając ewentualne treści oraz udostępniając je Usługobiorca dobrowolnie je rozpowszechniania. Serwis internetowy nie jest dostawcą treści i w żaden sposób nie utożsamia się z nimi, jest jedynie podmiotem, który udostępnia zasoby teleinformatyczne. Usługobiorca oświadcza, że: jest uprawniony do korzystania i udostępniania zamieszczanych przez siebie treści autorskich praw majątkowych, praw własności przemysłowej lub praw pokrewnych; umieszczenie oraz udostępnienie w ramach usług, danych osobowych, wizerunku, informacji dotyczących innych osób niż Usługobiorca odbyło się w sposób zgodny z prawem, dobrowolny oraz za zgodą właścicieli treści, których one dotyczą.</p>
                        <p className="indent">8.5 Usługobiorca nie jest uprawniony do: a) zamieszczania danych osobowych osób trzecich, rozpowszechniania wizerunku bez wymaganego zezwolenia lub zgody osoby trzeciej której dane te dotyczą; b) zamieszczania treści o charakterze reklamowym i lub promocyjnym, niezgodny z celem działalności Serwisu internetowego.</p>
                        <p className="indent">8.6 Zabronione jest zamieszczanie przez Usługobiorcę treści które mogłyby w szczególności: a) z zamiarem naruszenia dóbr osobistych osób trzecich; b) zamieszczonych w złej wierze bądź takich które mogły by być za takie uznane; c) naruszać prawa osób trzecich, praw autorskich, praw pokrewnych, praw własności przemysłowej, tajemnicy przedsiębiorstwa lub objętych klauzulą poufności zwłaszcza określanych jako tajne lub ściśle tajne; d) umieszczania treści obraźliwych bądź stanowiących groźbę skierowaną do innych osób, wypowiedzi powszechnie uważanych za obraźliwe np. wulgaryzmy; e) naruszać słuszne interesy Serwisu; f) rozsyłanie lub umieszczanie w ramach Serwisu Internetowego niezamówionej informacji handlowej (spam); g) naruszać w inny sposób dobre obyczaje - na przykład erotyka, przepisy obowiązującego prawa, normy społeczne lub obyczajowe; h) propagować poglądy nazistowskie, faszystowskie i im pokrewne.</p>
                        <p className="indent">8.7 W przypadku otrzymania powiadomienia przez osobę trzecią, uprawnioną bądź organ władzy Państwowej Serwis internetowy zastrzega sobie prawo do modyfikowania lub usuwania treści zamieszczanych przez Usługobiorcę, w sytuacji stwierdzenia że mogą one stanowić naruszenie niniejszego Regulaminu lub obowiązujących przepisów prawa. Serwis internetowy nie kontroluje na bieżąco zamieszczanych treści.</p>
                        <p className="indent">8.8 Usługodawca dołoży wszelkich starań w celu zapewnienia prawidłowego działania Serwisów i jego dostępności przez całą dobę, nie ponosi jednak odpowiedzialności za jakiekolwiek szkody wynikające z nieprawidłowego funkcjonowania Serwisów ze względów technicznych.</p>
                        <p className="indent">8.9 Serwis internetowy nie odpowiada również za ewentualne szkody urządzeń które korzystają z serwisu, restart urządzenia albo utrata danych na urządzeniu.</p>
                        <p className="indent">8.10 Usługodawca nie świadczy usług archiwizowania plików, danych lub informacji podawanych przez Usługobiorcę.</p>
                        <p className="indent">8.11 W przypadku naruszenia Regulaminu przez Usługobiorcę, Usługodawca może zawiesić świadczenie usług bądź rozwiązać ze skutkiem natychmiastowym umowę z Klientem poprzez wyłączenie/usunięcie aktywnych usług. W tej sytuacji Usługobiorcy nie przysługuje prawo do zwrotu żadnej z opłat poniesionych na rzecz Usługodawcy.</p>
                        <p className="indent">8.12 Usługodawca nie udziela Usługobiorcy jakiejkolwiek gwarancji ani wyraźnej, ani domniemanej, co do efektów i przydatności do określonych zastosowań oferty handlowej oraz jakości i ekonomicznych rezultatów działania oferty.</p>
                        <p className="indent">8.13 Usługodawca nie ponosi również odpowiedzialności za działania bądź zaniechania Usługobiorców, ani za nienależyte wykonanie bądź niewykonanie przez nich umów zawartych przez nich z Klientami w szczególności nie ponosi odpowiedzialności za jakość, bezpieczeństwo, legalność, prawdziwość i rzetelność informacji podawanych przez Usługobiorców. Usługodawca nie ponosi odpowiedzialności za nie zawarcie przez Odwiedzających umowy z Usługobiorcami a w szczególności związane z dokonanymi rezerwacjami, zapytaniami czy zawartymi umowami.</p>
                        <p className="indent">8.14 Usługodawca zastrzega sobie prawo do: zmiany parametrów i funkcjonalności Kont Usługobiorców; cech użytkowych i możliwości Serwisu, w szczególności zakresu oraz rodzaju Usług i jego funkcjonalności; okresowego wyłączenia Serwisu, związanego w szczególności z jego modyfikacją, konserwacją i naprawą; usunięcia z ważnych powodów całej zawartości serwerów Serwisów lub całkowitego zaprzestania świadczenia Usług, po uprzednim powiadomieniu Użytkowników na stronach Serwisu; zaprzestania świadczenia Usług w ramach Serwisu w stosunku do Usługobiorcy, który narusza postanowienia Regulaminu.</p>

                                        <h2> 9. Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</h2>
                        <p className="indent">9.1 Informacje o pozasądowych sposobach rozpatrywania reklamacji i dochodzenia roszczeń, a także zasady dostępu do tych procedur udostępniane są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników Konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona Konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami Urzędu Ochrony Konkurencji i Konsumentów: www.uokik.gov.pl/spory_konsumenckie.php, www.uokik.gov.pl/wazne_adresy.php, www.uokik.gov.pl/sprawy_indywidualne.php.</p>
                        <p className="indent">9.2 Usługobiorca posiada między innymi następujące możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia swoich roszczeń:</p>
                        <p className="indent">9.3 Zwrócenie się do Wojewódzkiego Inspektora Inspekcji Handlowej z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu.</p>
                        <p className="indent">9.4 Zwrócenie się do stałego polubownego Sądu Konsumenckiego działającego przy Wojewódzkim Inspektorze Inspekcji Handlowej z wnioskiem o rozstrzygnięcie sporu wynikłego z zawartej umowy, adres www.uokik.gov.pl/wazne_adresy.php.</p>
                        <p className="indent">9.5 Zwrócenie się o bezpłatną pomoc prawną m.in. do Federacji Konsumentów - adres strony internetowej: www.federacjakonsumentow.org.pl.</p>
                        <p className="indent">9.6 W rozwiązywaniu sporów transgranicznych pomaga Sieć Europejskich Centrów Konsumenckich. Adresy tych instytucji dostępne są na stronie internetowej Europejskiego Centrum Konsumenckiego www.konsument.gov.pl.</p>
                        <p className="indent">9.7 Usługobiorca może również skorzystać z platformy internetowego systemu rozstrzygania sporów (platforma ODR), zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) Nr 524/2013 z dnia 21 maja 2013 r. w sprawie internetowego systemu rozstrzygania sporów Konsumenckich oraz zmiany rozporządzenia (WE) nr 2006/2004 i dyrektywy 2009/22/WE (rozporządzenie w sprawie ODR w sporach konsumenckich). ODR (online dispute resolution) dostępnej pod adresem elektronicznym: http://ec.europa.eu/consumers/odr/ Europejska platforma ODR stanowi jeden wspólny punkt dostępu dla konsumentów i przedsiębiorców, umożliwiający pozasądowe rozstrzyganie sporów dotyczących zobowiązań umownych, wynikających z zawartej internetowej umowy usług: https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=PL.</p>
                        <p className="indent">9.8 Skorzystanie z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń ma charakter dobrowolny i może mieć miejsce tylko i wyłącznie gdy obie strony sporu tj Serwis internetowy i Usługobiorca wyrażą na to zgodę.</p>

                                          <h2>  10. Postanowienia dotyczące przedsiębiorców</h2>
                        <p className="indent">10.1 Regulację i postanowienia w niniejszym paragrafie 13 dotyczą tylko i wyłącznie Klientów i Usługobiorców nie będących konsumentami.</p>
                        <p className="indent">10.2 W wypadku klientów będących usługobiorcami i nie będących jednocześnie konsumentami usługodawca może wypowiedzieć umowę o świadczenie Usługi Elektronicznej ze skutkiem natychmiastowym nawet bez wskazywania przyczyn, pod warunkiem że wysłał klientowi stosowne oświadczenie.</p>
                        <p className="indent">10.3 Serwis internetowy informuje, że zgodnie z art. 558 § 1 Kodeksu Cywilnego odpowiedzialność z tytułu rękojmi za produkt/usługę wobec klienta nie będącego konsumentem zostaje wyłączona.</p>
                        <p className="indent">10.4 Odpowiedzialność Serwisu internetowego jest ograniczona w ramach pojedynczego roszczenia, jak i za wszystkie roszczenia w sumie, do wysokości 100 zł netto. Serwis internetowy ponosi odpowiedzialność tylko za typowe szkody przewidywalne w momencie zawarcia umowy i nie ponosi odpowiedzialności z tytułu utraconych korzyści.</p>
                        <p className="indent">10.5 Wszelkie spory między Serwisem internetowym a Usługobiorcą niebędącym konsumentem zostają poddane sądowi właściwemu ze względu na siedzibę Serwisu internetowego.</p>

                                            <h2>11. Metody płatności</h2>
                        <p className="indent">11.1 Płatności są realizowane za pośrednictwem serwisu płatności internetowych CashBill, a dostępne metody płatności to płatność elektroniczna kartą kredytową (Visa, Visa Electron, MasterCard, Maestro), przelewem internetowym bankowości elektronicznej, Direct Billing, PaySafeCard, BLIK, PayPal.</p>
                        <p className="indent">11.2 Serwis dokumentuje sprzedaż Towaru/Usługi.</p>

                                              <h2>12. Postanowienia końcowe</h2>
                        <p className="indent">12.1 Serwis internetowy honoruje wszelkie prawa Użytkowników przewidziane w przepisach obowiązującego prawa.</p>
                        <p className="indent">12.2 Jeśli obowiązujące prawo przyznaje Klientom będącym konsumentami korzystniejsze obowiązkowe i wymagane prawem uregulowania niż te, które są zawarte w niniejszym Regulaminie, odpowiednie postanowienia Regulaminu są bezpośrednio zastępowane przez konkretną normę obowiązującego prawa i tym samym są wiążące dla właściciela.</p>
                        <p className="indent">12.3 Wszelkie treści zamieszczone na stronie Serwisu internetowego (wliczając w to grafikę, teksty, układ stron i logotypy) korzystają z ochrony przewidzianej dla praw autorskich i są wyłączną własnością Serwisu. Wykorzystywanie tych treści bez pisemnej zgody Serwisu skutkuje odpowiedzialnością cywilną oraz karną.</p>
                        <p className="indent">12.4 Właściciel Serwisu, jako administrator danych osobowych, informuje, że: podanie danych jest zawsze dobrowolne, ale niezbędne do realizacji zamówienia; osoba podająca swoje dane osobowe ma nieograniczone prawo dostępu do wszystkich treści swoich danych i ich sprostowania, usunięcia (prawo do bycia zapomnianym), ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, dane mogą być udostępnione jednak właściwym organom państwa w sytuacji gdy odpowiedni przepis tego będzie wymagał; podstawą przetwarzania danych osobowych będzie art. 6 ust. 1 pkt a) oraz treść ogólnego rozporządzenia o ochronie danych; dane osobowe będą przechowywane i przetwarzane przez okres niezbędny do zakończenia przetwarzania i realizacji zamówienia, lecz nie dłużej niż przez okres 3 lat (2 lata okres to okres reklamacji i 1 rok na ewentualnie inne roszczenia i sytuacje wyjątkowe); osoba udostępniająca swoje dane osobowe ma prawo wniesienia skargi do UODO gdy uzna, że przetwarzanie danych osobowych dotyczących realizacji zamówienia narusza przepisy ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.</p>
                        <p className="indent">12.5 W zakresie przetwarzania danych osobowych niniejszego serwisu nie został stwierdzony przez Komisję Europejską odpowiedni stopień ochrony w drodze decyzji, ale dane będą odpowiednio zabezpieczone za pomocą rozwiązań i środków informatyczno-prawnych.</p>
                        <p className="indent">12.6 Państwa dane będą przetwarzane w sposób zautomatyzowany, w tym również w formie profilowania – w sytuacji wyrażenia zgody.</p>
                        <p className="indent">12.7 W pozostałych kwestiach nieuregulowanych zapisami niniejszego Regulaminu mają zastosowanie odpowiednie przepisy prawa polskiego.</p>
                        <p className="indent">12.8 Zmieniony Regulamin wiąże Użytkowników, jeżeli zostały zachowane wymagania określone w art. 384 Kodeksu cywilnego (tj. Użytkownik został prawidłowo powiadomiony o zmianach).</p>
                        <p className="indent">12.9 Serwis internetowy zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn, to jest: a) zmiany przepisów prawa; b) zmiany sposobu świadczenia usług drogą elektroniczną objętych regulaminem; c) zmiany danych Serwisu, w tym adresu poczty elektronicznej, numeru telefonu.</p>
                        <p className="indent">12.10 Zmiany regulaminu nie mają wpływu na Usługi już realizowane bądź zrealizowane, do nich zastosowanie ma regulamin obowiązujący w chwili przystąpienia do Usług Serwisu internetowego. Serwis internetowy o zamierzonej zmianie informuje na stronie co najmniej na 30 dni wcześniej. W przypadku braku akceptacji zmienionego regulaminu Usługobiorcy mogą w terminie 30 dni od dnia otrzymania wiadomości wypowiedzieć umowę ze skutkiem natychmiastowym.</p>
                        <p className="indent">12.11 Spory powstałe w wyniku świadczenia usług na podstawie niniejszego Regulaminu zostaną poddane rozstrzygnięciu Sądu Powszechnego, według wyboru Usługobiorcy będącego jednocześnie konsumentem, zgodnie z właściwymi przepisami prawa polskiego.</p>
                        <p className="indent">12.12 Załączniki do Regulaminu stanowią jego integralną część.</p>
                        <p className="indent">12.13 Usługobiorcy Serwisu mogą uzyskać dostęp do niniejszego Regulaminu w każdym czasie za pośrednictwem odsyłacza zamieszczonego na stronie głównej serwisu oraz pobrać go i sporządzić jego wydruk, komercyjne wykorzystanie podlega jednak ochronie Kancelarii Prawnej LEGATO.</p>
                        <p className="indent">12.14 Kopiowanie oraz rozpowszechnianie wzorca niniejszego Regulaminu jest zabronione i może podlegać odpowiedzialności zarówno karnej jak i cywilnej.</p>
                        <p className="indent">12.15 Regulamin wchodzi w życie z dniem 03.09.2024.</p>


                        <br/>
                        <p className="center">Data opublikowania regulaminu: 31.05.2024</p>

                      </>
                  )}
                  {currentRule === 'serwer' && (
                      <>
                        <h2>1. Zasady ogólne</h2>
                        <p className="indent">1.1 Kultura i szacunek: Szanuj innych graczy i zachowuj się kulturalnie
                          wobec społeczności serwera.</p>
                        <p className="indent">1.2 Netykieta: Przestrzegaj netykiety, unikaj nadmiernego używania CAPS
                          LOCK
                          i spamowania wiadomościami.</p>
                        <p className="indent">1.3 Ochrona danych osobowych: Nie udostępniaj prywatnych informacji ani
                          danych osobowych innych graczy bez ich zgody.</p>
                        <p className="indent">1.4 Czat i język: Unikaj wulgaryzmów, treści nieodpowiednich, obraźliwych
                          lub wulgarnych nazw graczy.</p>
                        <p className="indent">1.5 Unikaj cheatów: Graj fair i nie korzystaj z oszustw, modów lub
                          nielegalnych modyfikacji, które dają Ci nieuczciwą przewagę.</p>
                        <p className="indent">1.6 Ochrona środowiska: Szanuj środowisko na serwerze, zakazane jest
                          nadmierne niszczenie terenu.</p>
                        <p className="indent">1.7 Handel i ekonomia: Jeśli na serwerze obowiązuje ekonomia, uczciwie
                          przestrzegaj zasad handlu i nie oszukuj innych graczy.</p>
                        <p className="indent">1.8 Nagrywanie i streamowanie: Nagrywanie oraz streamowanie na serwerze
                          jest
                          dozwolone bez konieczności ogólnej zgody, jednakże w przypadku chęci reklamowania się lub
                          promowania swojego kanału/streamu na serwerze, trzeba zakupić rangę (Vip, Svip).
                          Jeżeli jesteś większym streamerem, youtuberem możesz poprosić administracje o specjalną rangę,
                          która ci pozwala na reklamowanie bez podanych rang.</p>
                        <p className="indent">1.9 Pomoc administracji: W przypadku problemów lub złamania zasad,
                          skontaktuj się z administracją serwera i respektuj jej decyzje.</p>

                        <h2>10. Zasady graczy</h2>
                        <p className="indent">2.1 Używanie cheatów i bugów: Ścisły zakaz używania cheatów, nieuczciwych
                          modyfikacji oraz wykorzystywania bugów gry, które dają nieuczciwą przewagę.</p>
                        <p className="indent">2.2 Nękanie i obrażanie: Zakazane jest nękanie, obrażanie i wszelkie
                          zachowania toksyczne wobec innych graczy. Szanuj innych członków społeczności.</p>
                        <p className="indent">2.3 Handel: Handel z innymi graczami powinien być uczciwy. Nie oszukuj w
                          trakcie transakcji.</p>
                        <p className="indent">2.4 Niszczenie świata: Unikaj nadmiernego niszczenia naturalnego świata
                          gry. Dbaj o estetykę i równowagę.</p>
                        <p className="indent">2.5 Naruszanie Prywatności: Zakazane jest wysyłanie informacji poufnych
                          takich jak Imion, Nazwisk, Adresów, nr telefonów itp.</p>
                        <p className="indent">2.6 Nieodpowiednia Komunikacja: Unikaj obraźliwego, toksycznego lub
                          nieodpowiedniego języka w czatach i komunikacji z innymi graczami.</p>
                        <p className="indent">2.7 Oszustwa i Hacking: Zakazane jest wszelkie oszustwa, oszukiwanie oraz
                          używanie nielegalnych programów do hakowania gry.</p>
                        <p className="indent">2.8 Wykorzystanie PvP: Choć na serwerze jest dozwolone PvP, nie nadużywaj
                          tej możliwości i nie atakuj innych graczy bez powodu.</p>
                        <p className="indent">2.9 Zakłócanie Rozgrywki: Nie przeszkadzaj innym graczom w rozgrywce i
                          współpracy.</p>
                        <p className="indent">2.10 Nadmierne Przeszkadzanie: Zabronione jest nadmierne przeszkadzanie
                          innym graczom i zakłócanie ich rozgrywki.</p>
                        <br/>
                        <p className="center">Data opublikowania regulaminu: 31.05.2024</p>
                      </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Rules;
