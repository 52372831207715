import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Product.css';

const products = [
    { id: 1, name: 'VIP', price: '200.00 PLN', sale: false },
    { id: 2, name: 'VIP', price: '200.00 PLN', sale: false },
    { id: 3, name: 'VIP', price: '200.00 PLN', sale: false },
    { id: 4, name: 'SVIP', price: '0.00 PLN', sale: true },
    { id: 5, name: 'SVIP', price: '0.00 PLN', sale: true },
];

const Product = () => {
    const navigate = useNavigate();

    const handlePurchase = () => {
        navigate('/payments'); // Redirect to the payments page
    };

    return (
        <div className="shop-wrapper">
            <div className="subtitle1">LISTA PRODUKTÓW</div>
            <hr className="paymetns-line" />
                <div className="shop">
                    <div className="shop-wrapper-out">
                        <div className="container-shop">
                            {products.map((product) => (
                                <div key={product.id} className="item-shop">
                                    <div className="product-name">{product.name}</div>
                                    <div className="product-description">Otrzymasz rangę {product.name}.<br />Ranga VIP na serwerze Minecraft daje graczom dostęp do ekskluzywnych korzyści, takich jak unikalne komendy, specjalne przywileje oraz wyróżniające się elementy kosmetyczne. Więcej szczegółów pod /vip</div>
                                    {product.sale && <div className="sale-tag">SALE 100%</div>}
                                    <div className="product-price">CENA: {product.price}</div>
                                    <button className="button-payments" onClick={handlePurchase}>KUP TERAZ</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
        </div>
    );
}


export default Product;
