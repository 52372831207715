import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './Footer.css';
import logo from './assets/nowelogo.png';

const Footer = () => {
    useEffect(() => {
        AOS.init({
            duration: 500,
            easing: 'ease',
            once: false,
        });
    }, []);

    return (
        <div className="footer-wrapper">
            <div className="footer" data-aos="zoom-in-up">
                <hr className="footer-line"/>
                <div className="footer-column">
                    <h2 className="header">SOCIAL MEDIA</h2>
                    <p><span className="bullet">•</span> <a href="https://discord.com/">DISCORD</a></p>
                    <p><span className="bullet">•</span> <a href="https://www.facebook.com/">FACEBOOK</a></p>
                    <p><span className="bullet">•</span> <a href="https://www.youtube.com/">YOUTUBE</a></p>
                    <p><span className="bullet">•</span> <a href="https://www.tiktok.com/">TIKTOK</a></p>
                </div>
                <div className="footer-logo-wrapper">
                    <div className="footer-logo">
                        <img src={logo} alt="Rust Logo"/>
                    </div>
                </div>
                <div className="footer-column">
                    <h2 className="header">NAWIGACJA</h2>
                    <p><span className="bullet">•</span> <a href="/">STRONA GŁÓWNA</a></p>
                    <p><span className="bullet">•</span> <a href="/sklep">SKLEP</a></p>
                    <p><span className="bullet">•</span> <a href="/regulamin">REGULAMIN</a></p>
                    <p><span className="bullet">•</span> <a href="/voucher">VOUCHER</a></p>
                </div>
                <div className="footer-disclaimer">
                    <p>PL - Serwer infinityrpg.pl nie jest w żaden sposób powiązany z firmą Mojang lub Microsoft. Dochody ze
                        sklepu przekazywane są na rzecz właściciela serwisu w celu utrzymania serwera</p>
                    <p>EN - infinityrpg.pl server is not associated with Mojang or Microsoft in any way. Income from the
                        store is transferred to the service owner to maintain the server.</p>
                </div>
                <div className="footer-disclaimer2">
                    <p>Właściciel – DAMIAN KRAUZE LUMINO STUDIO, NIP: 7722437856, REGON: 529474889.</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
