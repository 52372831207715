import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './PaymentConfirmationModal.css';

const PaymentConfirmationModal = ({ isOpen, onClose, transactionId, nick, email, variant, paymentMethod, promoCode, price }) => {
    const navigate = useNavigate();

    const handleCloseAndRedirect = () => {
        onClose();
        navigate('/');
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {}}
            className="payment-confirmation-modal"
            overlayClassName="payment-confirmation-modal-overlay"
        >
            <div className="payment-confirmation-content">
                <h2>Dziękujemy za dokonanie zakupu.</h2>
                <h3>Oto szczegóły Twojej transakcji:</h3>
                <p>Twój numer transakcji: {transactionId}</p>
                <p>Nick: {nick}</p>
                <p>Email: {email}</p>
                <p>Wariant produktu: {variant} dni</p>
                <p>Metoda płatności: {paymentMethod}</p>
                {promoCode && <p>Kod promocyjny: {promoCode}</p>}
                <p>Całkowita cena: {price} PLN</p>
                <h3>Potwierdzenie zostało wysłane na Twój adres e-mail.</h3>
                <button className="button-payments" onClick={handleCloseAndRedirect}>Powrót na stronę główną</button>
            </div>
        </Modal>
    );
};

export default PaymentConfirmationModal;
