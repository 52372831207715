import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5); 
  
    useEffect(() => {
      const timer = setTimeout(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1); 
        } else {
          navigate('/');
        }
      }, 1000);
  
      return () => clearTimeout(timer); 
    }, [countdown, navigate]);

  return (
      <div className="notfound-wrapper">
          <div className="title2" data-aos="zoom-in-up">
              <h2>ZREALIZUJ ZAMÓWIENIE</h2>
          </div>
          <hr className="notfound-line" data-aos="zoom-in-up"/>
          <div className="notfound"  data-aos="zoom-in-up">
              <div className="container-notfound-out">
                  <div className="container-notfound" data-aos="zoom-in-up">
                      <h2>404 - Nie znaleziono strony</h2>
                      <p>Przykro nam, strona, której szukasz, nie istnieje.</p>
                      <p>Przekierowanie za {countdown} sekund.</p>
                      <button className="button-notfound" onClick={() => navigate('/')}>Powrót na stronę główną</button>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default NotFound;
