import React, { useState } from 'react';
import './Home.css';
import logo from "../assets/img_2.png";
import rotatingIcon from "../assets/img_1.png";
import IPCopyModal from './IPCopyModal';
import { useNavigate } from 'react-router-dom';

const teamMembers = [
    { name: 'DIEGOYSP', role: 'WŁAŚCICIEL', uuid: '5872b547-8995-4b67-8c43-fc442cdf2705' },
    { name: 'JACOB', role: 'WŁAŚCICIEL', uuid: '5872b547-8995-4b67-8c43-fc442cdf2705' },
    { name: 'AKATERYUU', role: 'LIDER', uuid: '2782ca99-5c2d-46f7-86af-e980eeaafd2d' },
    { name: 'POBORX', role: 'MOD', uuid: '14683e2f-0c40-4430-af01-3efba153d4c1' },

];

const playersOnline = 500;
const maxSlots = 2137;

const isServerOnline = true;


function Home() {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCopyIP = () => {
        const serverIP = "infinityrpg.pl";
        navigator.clipboard.writeText(serverIP)
            .then(() => {
                setIsModalOpen(true);
            })
            .catch((err) => {
                console.error("Błąd przy kopiowaniu IP: ", err);
            });
    };

    const handleGoToShop = () => {
        navigate('/sklep');
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="home-wrapper">
            <div className="home">
                <div className="title2" data-aos="zoom-in-up">
                    <h2>INFORMACJE O SERWERZE</h2>
                </div>
                <hr className="home-line" data-aos="zoom-in-up"/>
                <div className="container-home-out" data-aos="zoom-in-up">
                    <div className="server-info" data-aos="zoom-in-up">
                        <div className="container-home" data-aos="zoom-in-up">
                            <h2>INFINITYRPG.PL</h2>
                            <p>
                                Hej, jak wiecie, Mojang nieśpiesznie wprowadza zmiany do Minecrafta, więc postanowiliśmy wziąć sprawy w swoje ręce. Tworząc nasz serwer, przyświecała nam myśl o zachowaniu klasycznego minecraftowego survivalu, ale z dodatkiem ciekawych i ekscytujących urozmaiceń.
                            </p>
                            <div className="button-container">
                                <button className="button-home" onClick={handleCopyIP}>KOPIUJ IP</button>
                                <button className="button-home2" onClick={handleGoToShop}>SKLEP</button>
                            </div>
                        </div>
                        <div className="image-content">
                            <img className="rotating-icon3" src={rotatingIcon} alt="Rotating Icon"/>
                            <img className="character-img" src={logo} alt="Character"/>
                        </div>
                    </div>
                    <div className="server-status-wrapper">
                        <div className="server-status2" title="Status serwera">
                            <div
                                className={`status-indicator ${isServerOnline ? 'status-online' : 'status-offline'}`}></div>
                            {isServerOnline ? (
                                <p>ONLINE: {playersOnline}/{maxSlots}</p>
                            ) : (
                                <p>SERWER OFFLINE</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="title2" data-aos="zoom-in-up">
                    <h2>ZWIASTUN SERWERA</h2>
                </div>
                <hr className="home-line" data-aos="zoom-in-up"/>
                <div className="container-home-out" data-aos="zoom-in-up">
                    <section data-aos="zoom-in-up">
                        <div className="trailer-content" data-aos="zoom-in-up">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/your_video_id"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            <div className="container-home-trailer">
                                <div className="trailer-info">
                                    <h2>Trailer 1 Edycji serwera</h2>
                                    <p>Pierwsza zapowiedź serwera! Jeśli chcesz nam pomóc w promocji serwera, udostępnij
                                        to
                                        dalej!</p>
                                    <button className="button-home-download">POBIERZ TRAILER</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="title2" data-aos="zoom-in-up">
                    <h2>NASZ TEAM</h2>
                </div>
                <hr className="home-line" data-aos="zoom-in-up"/>
                <div className="container-home-out3" data-aos="zoom-in-up">
                    <section data-aos="zoom-in-up">
                        <div className="team-members">
                            {teamMembers.map((member, index) => (
                                <div className="team-member" key={index}>
                                    <img
                                        src={`https://visage.surgeplay.com/head/512/${member.uuid}?no-shadow&y=70`}
                                        alt={`${member.name}'s head`}
                                    />
                                    <h3>{member.name}</h3>
                                    <p>{member.role}</p>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
            <IPCopyModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
}

export default Home;
