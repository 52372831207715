import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './NavigationBar.css';
import logo from './assets/nowelogo.png';
import rotatingIcon from './assets/img_1.png';

const NavigationBar = () => {
    useEffect(() => {
        AOS.init({
            duration: 500,
            easing: 'ease',
            once: false,
        });
    }, []);

    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [hoveredLink, setHoveredLink] = useState(null);
    const indicatorRef = useRef(null);

// Aktualizacja funkcji updateIndicatorPosition
    const updateIndicatorPosition = (path) => {
        const linkElement = document.querySelector(`.link[href="${path}"]`);
        const shopPath = '/sklep';
        const paymentsPath = '/payments';

        if (linkElement && indicatorRef.current) {
            const { offsetLeft, offsetWidth } = linkElement;
            indicatorRef.current.style.transform = `translateX(${offsetLeft}px)`;
            indicatorRef.current.style.width = `${offsetWidth}px`;
            indicatorRef.current.classList.add('visible');
        } else if (path.startsWith(shopPath) || path.startsWith(paymentsPath)) {
            const linkElement = document.querySelector(`.link[href="${shopPath}"]`);
            if (linkElement && indicatorRef.current) {
                const { offsetLeft, offsetWidth } = linkElement;
                indicatorRef.current.style.transform = `translateX(${offsetLeft}px)`;
                indicatorRef.current.style.width = `${offsetWidth}px`;
                indicatorRef.current.classList.add('visible');
            }
        } else {
            indicatorRef.current.classList.remove('visible');
        }
    };





    useLayoutEffect(() => {
        const handleResize = () => {
            updateIndicatorPosition(activeLink);
        };

        window.addEventListener('resize', handleResize);

        // Updating the position after initial render and DOM updates
        const updateAfterLoad = setTimeout(() => {
            updateIndicatorPosition(hoveredLink || activeLink);
        }, 50);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(updateAfterLoad);
        };
    }, [hoveredLink, activeLink]);

    useEffect(() => {
        setActiveLink(location.pathname);
        updateIndicatorPosition(location.pathname);
    }, [location.pathname]);

    const handleMouseEnter = (path) => {
        setHoveredLink(path);
    };

    const handleMouseLeave = () => {
        setHoveredLink(null);
    };

    const cleanPath = (path) => {
        if (path === '/') {
            return path;
        } else if (path.endsWith("/")) {
            return path.slice(0, -1);
        }
        return path;
    };


    const handleLinkClick = (path) => {
        setActiveLink(cleanPath(path));
        updateIndicatorPosition(cleanPath(path));
    };

    useEffect(() => {
        setActiveLink(cleanPath(location.pathname));
        updateIndicatorPosition(cleanPath(location.pathname));
    }, [location.pathname]);


    return (
        <div className="navbar-wrapper">
            <div className="navbar" data-aos="zoom-in-up">
                <div className="navbar-logo" data-aos="zoom-in-up">
                    <img src={logo} alt="Logo"/>
                    <img className="rotating-icon" src={rotatingIcon} alt="Rotating Icon" />
                </div>
                <div className="navbar-links" data-aos="zoom-in-up">
                    {['/', '/regulamin', '/voucher', '/rankingi', '/sklep'].map((path) => (
                        <Link
                            key={path}
                            to={path}
                            className={`link ${activeLink === path ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter(path)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleLinkClick(path)}
                        >
                            {path === '/' ? 'STRONA GŁÓWNA' : path.substring(1).toUpperCase()}
                        </Link>
                    ))}
                    <div className="active-indicator" ref={indicatorRef}/>
                </div>
            </div>
        </div>
    );
};

export default NavigationBar;
