import React, { useEffect, useState } from 'react';
import './Payments.css';
import PaymentConfirmationModal from "./PaymentConfirmationModal";
import AOS from "aos";

const Payments = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [transactionId, setTransactionId] = useState('');

    const [nick, setNick] = useState('');
    const [email, setEmail] = useState('');
    const [variant, setVariant] = useState('30');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [nickError, setNickError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [promoCodeError, setPromoCodeError] = useState('');
    const [globalDiscount, setGlobalDiscount] = useState(false);
    const [promoCodeDiscount, setPromoCodeDiscount] = useState(false);

    const basePrice = 200.00;
    const globalDiscountRate = 0.20; // 20% discount



    useEffect(() => {
        AOS.init({
            duration: 500,
            easing: 'ease',
            once: false,
        });
    }, []);

    const validateNick = (value) => {
        const regex = /^[a-zA-Z0-9]{1,32}$/;
        if (value && !regex.test(value)) {
            setNickError('Nick powinien zawierać wyłącznie litery i cyfry oraz mieć maksymalnie 32 znaki.');
        } else {
            setNickError('');
        }
    };

    const validateEmail = (value) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value && !regex.test(value)) {
            setEmailError('Niewłaściwy adres email.');
        } else {
            setEmailError('');
        }
    };

    const validatePromoCode = (value) => {
        const regex = /^[a-zA-Z0-9]{0,10}$/;
        if (value && !regex.test(value)) {
            setPromoCodeError('Kod promocyjny powinien zawierać wyłącznie litery i cyfry oraz mieć maksymalnie 10 znaków.');
        } else {
            setPromoCodeError('');
            setPromoCodeDiscount(value === 'PROMO10');
        }
    };

    const calculatePrice = () => {
        let price = basePrice;
        let discount = 0;

        if (globalDiscount) {
            discount += globalDiscountRate;
        }
        if (promoCodeDiscount) {
            discount += 0.10;
        }
        price *= (1 - discount);

        return price.toFixed(2);
    };

    const calculateDiscountAmount = () => {
        let discount = 0;

        if (globalDiscount) {
            discount += globalDiscountRate;
        }
        if (promoCodeDiscount) {
            discount += 0.10;
        }
        return (discount * basePrice).toFixed(2);
    };

    const handlePurchase = () => {
        if (!termsAccepted) {
            alert('Musisz zaakceptować regulamin.');
            return;
        }

        if (nickError || emailError || promoCodeError) {
            alert('Popraw błędy w formularzu.');
            return;
        }
        setTransactionId('123456789');
        // Otwieramy modal
        setIsModalOpen(true);

        console.log('Zamówienie złożone', { nick, email, variant, paymentMethod, promoCode, price: calculatePrice() });
    };

    const isFormValid = nick && email && paymentMethod && termsAccepted && !nickError && !emailError && !promoCodeError;

    return (
        <div className="paymetns-wrapper">
            <div className="paymetns">
                <div className="title1" data-aos="zoom-in-up">
                    <h2>ZREALIZUJ ZAMÓWIENIE</h2>
                </div>
                <hr className="paymetns-line" data-aos="zoom-in-up"/>
                <div className="container-payments-out">
                    <div className="container-payments" data-aos="zoom-in-up">
                        <PaymentConfirmationModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            transactionId={transactionId}
                            nick={nick}
                            email={email}
                            variant={variant}
                            paymentMethod={paymentMethod}
                            promoCode={promoCode}
                            price={calculatePrice()}
                        />
                        <h1 className="subtitle1">DANE GRACZA</h1>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    value={nick}
                                    onChange={(e) => {
                                        setNick(e.target.value);
                                        validateNick(e.target.value);
                                    }}
                                    placeholder="Wpisz swój nick z gry..."
                                />
                                {nickError && <div className="error">{nickError}</div>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        validateEmail(e.target.value);
                                    }}
                                    placeholder="Wpisz swój e-mail..."
                                />
                                {emailError && <div className="error">{emailError}</div>}
                            </div>
                        </div>
                        <h2 className="subtitle1">WARIANT PRODUKTU</h2>
                        <div className="form-group slider-group">
                            <label>Wybrany wariant: {variant} dni</label>
                            <input
                                type="range"
                                min="1"
                                max="300"
                                value={variant}
                                onChange={(e) => setVariant(e.target.value)}
                                className="slider"
                            />
                        </div>
                        <h2 className="subtitle1">METODA PŁATNOŚCI</h2>
                        <div className="form-group payment-methods">
                            {['przelew', 'paysafecard', 'sms', 'paypal'].map((method) => (
                                <label
                                    key={method}
                                    className={`payment-option ${paymentMethod === method ? 'payment-option-selected' : ''}`}
                                >
                                    <input
                                        type="radio"
                                        value={method}
                                        checked={paymentMethod === method}
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                        required
                                    />
                                    <span>{method.charAt(0).toUpperCase() + method.slice(1)}</span><br/> CENA: {calculatePrice()} PLN
                                </label>
                            ))}
                        </div>
                        <h2 className="subtitle1">FINALIZACJA ZAMÓWIENIA</h2>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    value={promoCode}
                                    onChange={(e) => {
                                        setPromoCode(e.target.value);
                                        validatePromoCode(e.target.value);
                                    }}
                                    placeholder="Wpisz kod promocyjny..."
                                />
                                {promoCodeError && <div className="error">{promoCodeError}</div>}
                            </div>
                            <div className="form-group product-info">
                                <h2 className="product-text">Produkt: VIP</h2>
                            </div>
                        </div>
                        <div className="form-group terms">
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    checked={termsAccepted}
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                    required
                                />
                                <span className="checkmark"></span>
                                Przeczytałem/am i akceptuję regulamin
                            </label>
                        </div>
                        <div className="form-group terms">
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    checked={globalDiscount}
                                    onChange={(e) => setGlobalDiscount(e.target.checked)}
                                />
                                <span className="checkmark"></span>
                                Zastosuj globalny rabat 20%
                            </label>
                        </div>
                        <button
                            onClick={handlePurchase}
                            disabled={!isFormValid}
                            className={`button-payments ${!isFormValid ? 'disabled' : ''}`}
                        >
                            Kup teraz
                            - {calculatePrice()} PLN {calculateDiscountAmount() > 0 && ` (Rabat: ${calculateDiscountAmount()} PLN)`}
                        </button>

                        <p className="disclaimer">
                            Za płatność odpowiada www.cashbill.pl. Formularz reklamacyjny oraz przechodząc do płatności
                            akceptujesz regulamin oraz wyrażasz zgodę na spełnienie świadczenia przed upływem terminu na
                            odstąpienie od umowy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payments;
