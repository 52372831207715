import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './IPCopyModal.css';

const IPCopyModal = ({ isOpen, onClose }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="ip-copy-modal"
            overlayClassName="ip-copy-modal-overlay"
        >
            <div className="ip-copy-content">
                <h2>IP serwera skopiowane do schowka!</h2>
                <button className="button-close" onClick={onClose}>Zamknij</button>
            </div>
        </Modal>
    );
};

export default IPCopyModal;
